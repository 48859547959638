<template>
    <v-row no-gutters align="center">
        <v-col>
            <v-card width="100%" class="mx-auto">
                <h2 class="title">Order ID: {{ orderId }}</h2>
                <v-card-title>Scan for Cart ID</v-card-title>
                <v-alert
                    v-show="error != ''"
                    color="#4c9aff"
                    border="left"
                    colored-border
                    type="error"
                    elevation="2"
                >
                    {{ error }}
                </v-alert>
                <ScannableInput :active="true" :debug="false" @scan="scan" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ScannableInput from '@/components/ScannableInput';
import * as data from '@/data/index';

export default {
    data() {
        return {
            error: ''
        };
    },
    components: {
        ScannableInput
    },
    computed: {
        orderId() {
            return this.$route.params.order_id;
        }
    },
    methods: {
        scan(response) {
            this.error = '';
            const patternToMatchNumberOnly = /^\d+$/;
            if (patternToMatchNumberOnly.test(response.input) === false) {
                this.error = 'Only numeric value is allowed for the cart-id.';
                return;
            }
            let self = this;
            self.$root.$dialogLoader.show('Assigning new cart-id...', {});
            data.verifications
                .getByCart(response.input)
                .then(async querySnapshot => {
                    if (querySnapshot.size) {
                        return Promise.reject(
                            new Error(
                                'This cart-id has been taken by another order.'
                            )
                        );
                    }
                    return self.$store.dispatch(
                        'assignCurrentOrder',
                        self.orderId
                    );
                })
                .then(
                    () => {
                        self.$store.dispatch('updateVerification', {
                            orderId: self.orderId,
                            data: { cartId: response.input }
                        });
                        self.$router.push({
                            name: 'Order',
                            params: {
                                order_type: '',
                                show_next_order_button: false,
                                show_alert: false,
                                alert_message: ''
                            }
                        });
                        self.$root.$dialogLoader.hide();
                    },
                    error => {
                        self.error = error.message;
                        self.$root.$dialogLoader.hide();
                    }
                );
        }
    }
};
</script>
