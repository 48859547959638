<template>
    <v-card elevation="0" class="mx-auto text-center" width="100%">
        <v-alert
            v-show="error != ''"
            color="secondary"
            border="left"
            colored-border
            type="error"
            elevation="2"
            class="mt-6"
        >
            {{ error }}
        </v-alert>
        <v-card elevation="0" class="mt-12">
            <h1 class="justify-center">User Activity</h1>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-menu
                            v-model="showDateStartFilter"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFilterStart"
                                    label="Start From"
                                    prepend-icon="fa-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                color="secondary"
                                v-model="dateFilterStart"
                                @input="showDateStartFilter = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-menu
                            v-model="showDateEndFilter"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFilterEnd"
                                    label="Start To"
                                    prepend-icon="fa-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                color="secondary"
                                v-model="dateFilterEnd"
                                @input="showDateEndFilter = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="secondary" @click="loadReport">Show Report</v-btn>
                <v-btn color="secondary" @click="resetFilters"
                    >Clear Filters</v-btn
                >
            </v-card-actions>
        </v-card>

        <v-card width="100%" flat class="mx-auto" v-if="showReport">
            <v-card-actions class="align-right">
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="exportReport">Export</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            <v-data-table
                :headers="headers"
                disable-sort
                hide-default-header
                :items="report"
                :items-per-page="10"
                disable-pagination
                hide-default-footer
                show-select
                class="elevation-2 mx-auto complete-table"
            >
                <template #body="{ items }">
                    <thead>
                        <tr>
                            <th>Order Number</th>
                            <th>Persons Name</th>
                            <th>Action Taken</th>
                            <th>Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in items" :key="i">
                            <td>
                                {{ item.order_id }}
                            </td>
                            <td>
                                {{ item.user }}
                            </td>
                            <td>
                                {{ item.description }}
                            </td>
                            <td>
                                {{ item.created }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card>
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { orderHistory, CsvExporter } from '@/data/index';

export default {
    data() {
        return {
            error: '',
            showDateStartFilter: false,
            showDateEndFilter: false,
            dateFilterStart: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            dateFilterEnd: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            report: [],
            headers: [],
            showReport: false
        };
    },
    computed: {
        ...mapState(['currentUser'])
    },
    mounted() {
        if (!this.currentUser.role.includes('ADMIN')) {
            this.$router.push({
                name: 'Home'
            });
        }
    },
    methods: {
        async loadReport() {
            let self = this;
            self.error = '';
            self.report = [];
            self.$root.$dialogLoader.show('Fetching data…', {});
            const start = Date.parse(this.dateFilterStart);
            const end = Date.parse(`${this.dateFilterEnd} 23:59:59`);
            const userActivity = await orderHistory.getOrdersActivityWithinDateRange(
                start,
                end
            );
            if (!userActivity.length) {
                self.error =
                    'There was no record found matching the selections.';
                self.showReport = false;
                self.$root.$dialogLoader.hide();
                return;
            }
            userActivity.forEach(item => {
                let data = item.data();
                delete data['action'];
                data['created'] = self.convertUnixTimestampToDateTime(
                    data['created']
                );
                self.report = self.report.concat(data);
            });
            self.showReport = true;
            self.$root.$dialogLoader.hide();
        },

        convertUnixTimestampToDateTime(timestamp) {
            if (!timestamp) {
                return timestamp;
            }
            let date = new Date(timestamp);
            date = date.toLocaleString();
            date = date.replace(', ', '-');
            return date;
        },

        resetFilters() {
            this.$root.$dialogLoader.show('Please wait…', {});
            this.dateFilterStart = new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10);
            this.dateFilterEnd = new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10);
            this.report = [];
            this.showReport = false;
            this.$root.$dialogLoader.hide();
        },

        exportReport() {
            const self = this;
            const data = self.report.map(item => item);
            CsvExporter.createCsv(data, 'user-activity.csv');
        }
    }
};
</script>
