<template>
    <v-app class="flex-fill">
        <main>
            <v-alert
                v-model="show_notification"
                color="#4c9aff"
                border="left"
                colored-border
                type="error"
                elevation="2"
                dismissible
            >
                {{ notification_message }}
            </v-alert>
            <v-container fluid>
                <v-layout flex align-center justify-center>
                    <v-card width="100%" class="mx-auto mt-5">
                        <v-form ref="loginForm">
                            <v-card-title class="pb-0 mb-5">
                                <h3>Login</h3>
                            </v-card-title>
                            <div v-if="error" class="error--text">
                                <div class="v-messages__message">
                                    {{ error }}
                                </div>
                            </div>
                            <v-card-text>
                                <v-text-field
                                    label="Username"
                                    prepend-icon="fa-user"
                                    v-model.trim="loginForm.username"
                                    :rules="[rules.required]"
                                />
                                <v-text-field
                                    :type="showPassword ? 'text' : 'password'"
                                    label="Password"
                                    prepend-icon="fa-lock"
                                    :append-icon="
                                        showPassword ? 'fa-eye' : 'fa-eye-slash'
                                    "
                                    @click:append="showPassword = !showPassword"
                                    v-model.trim="loginForm.password"
                                    :rules="[rules.required]"
                                />
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn
                                    color="secondary"
                                    @click.once="login"
                                    :key="loginButtonKey"
                                    >Login</v-btn
                                >
                                <!-- <a href="/reset-password" class="ml-auto"
                                    >Reset Password</a
                                > -->
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-layout>
            </v-container>
        </main>
    </v-app>
</template>

<script>
import { config, timeTracker } from '@/data/index';

export default {
    props: {
        show_notification: {
            type: Boolean,
            default: false
        },
        notification_message: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loginForm: {
                username: '',
                password: ''
            },
            rules: {
                required: value => !!value || 'Required.'
            },
            showPassword: false,
            error: null,
            loginButtonKey: 1
        };
    },
    methods: {
        async login() {
            let self = this;
            if (!this.$refs.loginForm.validate()) {
                this.loginButtonKey++;
                return false;
            }
            self.$root.$dialogLoader.show('Please wait…', {});
            let username = this.loginForm.username;
            if (!this.isEmailEntered(username)) {
                const emailSuffix = '@mobile-pulling.web.app';
                username = username + emailSuffix;
            }
            this.$store
                .dispatch('login', {
                    email: username,
                    password: this.loginForm.password
                })
                .then(
                    () => {
                        self.resumePausedActivity();
                        self.$root.$dialogLoader.hide();
                    },
                    error => {
                        this.error = error.message;
                        this.loginButtonKey++;
                        self.$root.$dialogLoader.hide();
                    }
                );
        },

        isEmailEntered(username) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(username);
        },

        async resumePausedActivity() {
            let self = this;
            const lastPausedActivity = await this.getLastPausedTrackedActivity();
            if (lastPausedActivity != '') {
                self.$root.$dialogLoader.show('Resuming last activity…', {});
                timeTracker
                    .startTimer(lastPausedActivity)
                    .then(async docRef => {
                        self.$store.dispatch('setActiveTimeTracker', docRef.id);
                        self.$store.dispatch('setActiveTimeTrackingOperation', {
                            id: lastPausedActivity,
                            name: await config.getTimeTrackingoperationById(
                                lastPausedActivity
                            )
                        });
                        self.$root.$dialogLoader.hide();
                        self.show = false;
                    })
                    .catch(error => {
                        console.error('Error adding document: ', error);
                        self.$root.$dialogLoader.hide();
                        self.show = false;
                    });
            }
        },

        async getLastPausedTrackedActivity() {
            return timeTracker.getLastTrackedActivity().then(querySnapshot => {
                let lastActivity = querySnapshot.docs.map(snap => snap.data());
                if (!lastActivity || !lastActivity.length) {
                    return '';
                }
                lastActivity = lastActivity[0];
                if (lastActivity.isPausedByLogout == true) {
                    return lastActivity.operation;
                }
                return '';
            });
        }
    }
};
</script>
