var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto text-center",attrs:{"elevation":"0","width":"100%"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.error != ''),expression:"error != ''"}],staticClass:"mt-6",attrs:{"color":"secondary","border":"left","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('v-card',{staticClass:"mt-12",attrs:{"elevation":"0"}},[_c('h1',{staticClass:"justify-center"},[_vm._v("Time Tracker Reports")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.users,"label":"Please select a user","item-value":"id","item-text":"email","outlined":""},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start From","prepend-icon":"fa-calendar","readonly":""},model:{value:(_vm.dateFilterStart),callback:function ($$v) {_vm.dateFilterStart=$$v},expression:"dateFilterStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateStartFilter),callback:function ($$v) {_vm.showDateStartFilter=$$v},expression:"showDateStartFilter"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.showDateStartFilter = false}},model:{value:(_vm.dateFilterStart),callback:function ($$v) {_vm.dateFilterStart=$$v},expression:"dateFilterStart"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start To","prepend-icon":"fa-calendar","readonly":""},model:{value:(_vm.dateFilterEnd),callback:function ($$v) {_vm.dateFilterEnd=$$v},expression:"dateFilterEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateEndFilter),callback:function ($$v) {_vm.showDateEndFilter=$$v},expression:"showDateEndFilter"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.showDateEndFilter = false}},model:{value:(_vm.dateFilterEnd),callback:function ($$v) {_vm.dateFilterEnd=$$v},expression:"dateFilterEnd"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.loadReport}},[_vm._v("Show Report")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.resetFilters}},[_vm._v("Clear Filters")])],1)],1),(_vm.showReport)?_c('v-card',{staticClass:"mx-auto",attrs:{"width":"100%","flat":""}},[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.selectedUser == '' ? 'Time Tracking Report' : _vm.selectedUser in _vm.usersEmailById ? ("Time Tracked by " + (_vm.usersEmailById[_vm.selectedUser])) : 'Time Tracking Report'))])]),(!_vm.loadingData)?_c('v-data-table',{staticClass:"elevation-2 mx-auto complete-table",attrs:{"headers":_vm.headers,"disable-sort":"","hide-default-header":"","items":_vm.report,"items-per-page":10,"disable-pagination":"","hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('thead',[_c('tr',[_c('th',[_vm._v("Operation")]),_c('th',[_vm._v("Start At")]),_c('th',[_vm._v("Stop At")]),_c('th',[_vm._v("User")])])]),_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i,class:{ invalid: !item.stop }},[_c('td',[_vm._v(" "+_vm._s(item.operation in _vm.operations ? _vm.operations[item.operation] : item.operation)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.convertUnixTimestampToDateTime(item.start))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.convertUnixTimestampToDateTime(item.stop))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.user in _vm.usersEmailById ? _vm.usersEmailById[item.user] : item.user)+" ")])])}),0)]}}],null,false,1552824536)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }