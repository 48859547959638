<template>
    <v-app>
        <main>
            <v-container fluid class="py-6">
                <v-layout flex align-center justify-center>
                    <v-card width="400" class="mx-auto mt-5">
                        <v-form
                            ref="resetPasswordForm"
                            @submit.prevent="resetPassword"
                        >
                            <v-card-title class="pb-0 mb-5">
                                <h1>Forgot Password</h1>
                            </v-card-title>
                            <div v-if="error" class="error--text">
                                <div class="v-messages__message">
                                    {{ error }}
                                </div>
                            </div>
                            <v-card-text>
                                <v-text-field
                                    label="Username"
                                    prepend-icon="fa-user"
                                    v-model.trim="username"
                                    :rules="[rules.required, rules.email]"
                                />
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn color="secondary" type="submit"
                                    >Reset password</v-btn
                                >
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-layout>
            </v-container>
        </main>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail.';
                }
            },
            error: null
        };
    },
    methods: {
        resetPassword() {
            if (this.$refs.resetPasswordForm.validate()) {
                this.$store.dispatch('resetPassword', this.username).then(
                    // eslint-disable-next-line no-unused-vars
                    response => {
                        this.$router.push({
                            name: 'Login',
                            params: {
                                show_notification: true,
                                notification_message:
                                    'Password reset email sent.'
                            }
                        });
                    },
                    error => {
                        this.error = error.message;
                    }
                );
            }
        }
    }
};
</script>
