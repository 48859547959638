<template>
    <v-card width="100%" class="mx-auto">
        <v-card-title>Search Orders by SKU</v-card-title>
        <v-alert
            v-show="error != ''"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
        >
            {{ error }}
        </v-alert>

        <v-form>
            <v-card-text>
                <v-text-field
                    label="SKU"
                    v-model.trim="sku"
                    :rules="[rules.required]"
                />
            </v-card-text>
            <v-card-actions class="px-4 py-4">
                <v-btn
                    color="primary"
                    type="submit"
                    v-show="isSearchCompleted == false"
                    @click.prevent="searchForOrders"
                    >Search</v-btn
                >
            </v-card-actions>
            <v-card-subtitle v-show="getSearchedOrdersCount > 0"
                >A total of <b>{{ getSearchedOrdersCount }}</b> order(s) found
                matching the sku <i>"{{ sku }}"</i></v-card-subtitle
            >
            <v-card-actions
                class="px-4 py-4"
                v-show="isSearchCompleted == true"
            >
                <v-btn
                    color="primary"
                    type="button"
                    @click.prevent="PullAllOrders"
                    >Pull all orders</v-btn
                >
                <v-btn
                    color="primary"
                    type="button"
                    @click.prevent="resetSearch"
                    >Search again</v-btn
                >
                <v-btn
                    color="primary"
                    type="button"
                    @click.stop="showUsersList = true"
                >
                    <v-icon small class="mr-2">fa-user-tag</v-icon>
                    Assign to User
                </v-btn>
            </v-card-actions>
        </v-form>

        <v-dialog v-model="showUsersList" max-width="600px" persistent>
            <v-card>
                <v-card-title class="headline">
                    Assign order to
                </v-card-title>
                <v-card-text>
                    <v-select
                        v-model="selectedUser"
                        :items="users"
                        label="Please select a user"
                        item-value="id"
                        item-text="email"
                        outlined
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="showUsersList = false">
                        Cancel
                    </v-btn>

                    <v-btn
                        color="secondary"
                        :disabled="selectedUser == ''"
                        @click="
                            (showUsersList = false), assignUserToBatchOrders()
                        "
                    >
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import * as data from '@/data/index';
import { mapState } from 'vuex';

export default {
    name: 'BatchOrderSkuSearch',
    data() {
        return {
            error: '',
            sku: '',
            rules: {
                required: value => !!value || 'The field is required.'
            },
            isSearchCompleted: false,
            searchedOrders: [],
            showUsersList: false,
            selectedUser: ''
        };
    },

    computed: {
        ...mapState(['currentUser', 'users']),
        getSearchedOrdersCount() {
            return this.searchedOrders.length;
        }
    },

    methods: {
        async searchForOrders() {
            const self = this;
            self.error = '';
            self.$root.$dialogLoader.show('Searching for orders...', {});
            data.orders
                .getOrdersByItem(self.sku)
                .then(querySnapshot => {
                    self.searchedOrders = querySnapshot.docs.map(doc =>
                        doc.data()
                    );
                    if (self.searchedOrders.length > 0) {
                        self.isSearchCompleted = true;
                    } else {
                        self.error = `There was no new order found matching matching the SKU (${self.sku})`;
                    }
                    self.$root.$dialogLoader.hide();
                })
                .catch(err => {
                    console.error(err);
                    self.$root.$dialogLoader.hide();
                });
        },

        assignUserToBatchOrders() {
            const self = this;
            self.$root.$dialogLoader.show('Assigning user...', {});

            let promises = self.searchedOrders.map(order => {
                return self.$store
                    .dispatch('updateOrder', {
                        orderId: order.order.id,
                        data: {
                            assigned: self.selectedUser
                        }
                    })
                    .then(() => {
                        return data.orderHistory.save({
                            orderId: order.order.id,
                            action: 'assigned',
                            description:
                                'Assigned order to ' +
                                self.getSelectedUserDisplayName()
                        });
                    });
            });
            Promise.all(promises)
                .then(() => {
                    self.$root.$dialogLoader.hide();
                })
                .catch(err => {
                    console.error(err);
                    self.error = 'There was an error during user assignment.';
                    self.$root.$dialogLoader.hide();
                });
        },

        getSelectedUserDisplayName() {
            let self = this;
            let displayName = '';

            self.users.every(user => {
                if (user.id == self.selectedUser) {
                    displayName = user.displayName;
                    return false;
                }
                return true;
            });
            return displayName;
        },

        async PullAllOrders() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'Are you sure you want to pull all orders?',
                    {}
                )
            ) {
                this.$root.$dialogLoader.show('Claiming Batch Orders..', {});
                this.claimBatchOrders()
                    .then(() => {
                        this.$root.$dialogLoader.updateMessage(
                            'Assigning Cart IDs...'
                        );
                        return this.assignCartIdToBatchOrders();
                    })
                    .then(() => {
                        this.$root.$dialogLoader.updateMessage(
                            'Completing Batch Order Items Manually..'
                        );
                        return this.completeBatchOrdersManually();
                    })
                    .then(() => {
                        this.$root.$dialogLoader.updateMessage(
                            'Print and Packing Batch Orders...'
                        );
                        return this.printAndPackBatchOrders();
                    })
                    .then(() => {
                        this.$root.$dialogLoader.hide();
                        this.showReport();
                    })
                    .catch(err => {
                        this.error =
                            'There was a problem in processing batch orders';
                        this.$root.$dialogLoader.hide();
                        console.error(err);
                    });
            }
        },

        claimBatchOrders() {
            const self = this;
            let promises = self.searchedOrders.map(order => {
                return self.$store
                    .dispatch('updateOrder', {
                        orderId: order.order.id,
                        data: {
                            assigned: self.currentUser.uid,
                            internalStatus: 'in-progress'
                        }
                    })
                    .then(() => {
                        return data.orderHistory.save({
                            orderId: order.order.id,
                            action: 'pulling-start',
                            description:
                                'Order pulling is started as a part of Batch Order by ' +
                                self.currentUser.displayName
                        });
                    });
            });
            return Promise.all(promises);
        },

        assignCartIdToBatchOrders() {
            const self = this;
            let promises = self.searchedOrders.map(order => {
                return self.$store.dispatch('updateVerification', {
                    orderId: order.order.id,
                    data: { cartId: order.order.id.toString() }
                });
            });
            return Promise.all(promises);
        },

        completeBatchOrdersManually() {
            const self = this;
            let promises = self.searchedOrders.map(order => {
                return self.completeOrderItemsManually(order);
            });
            return Promise.all(promises);
        },

        async completeOrderItemsManually(order) {
            const self = this;
            order.verification = await data.orders.getVerificationForOrder(
                order,
                this.currentUser
            );
            let promises = order.verification.items.map(item => {
                return self.$store.dispatch('updateVerificationItem', {
                    orderId: order.order.id,
                    itemId: item.orderItem.id,
                    data: {
                        status: 'complete',
                        confirm: {
                            method: 'manual'
                        }
                    }
                });
            });
            return Promise.all(promises);
        },

        printAndPackBatchOrders() {
            const self = this;
            let promises = self.searchedOrders.map(order => {
                return self.$store
                    .dispatch('updateOrder', {
                        orderId: order.order.id,
                        data: {
                            internalStatus: 'needs-shipped',
                            assigned: null
                        }
                    })
                    .then(() => {
                        return data.orderHistory.save({
                            orderId: order.order.id,
                            action: 'ready-to-ship',
                            description:
                                'Order finished picking by ' +
                                self.currentUser.displayName
                        });
                    });
            });
            return Promise.all(promises);
        },

        resetSearch() {
            this.$router.go();
        },

        showReport() {
            this.$router.push({
                name: 'BatchOrdersReport',
                params: {
                    orders: this.searchedOrders
                }
            });
        }
    }
};
</script>
