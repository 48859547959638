<template>
    <v-row justify="center">
        <v-dialog
            v-model="show"
            transition="dialog-top-transition"
            persistent
            max-width="290"
        >
            <v-card>
                <v-btn
                    icon
                    color="secondary"
                    class="place-top-right"
                    @click="show = false"
                >
                    <v-icon>fa-times</v-icon>
                </v-btn>
                <v-card-title class="text-h5">
                    Time Tracker
                </v-card-title>
                <v-card-text>
                    <p>{{ timer }}</p>
                    <v-select
                        v-model="selectedOperation"
                        :items="operations"
                        label="Choose an Operation"
                        item-value="id"
                        item-text="name"
                        return-object
                    ></v-select
                ></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        v-show="activeTimeTracker == ''"
                        @click="setStartTime"
                    >
                        Start
                    </v-btn>
                    <v-btn
                        color="secondary"
                        v-show="activeTimeTracker != ''"
                        @click="updateOperation"
                    >
                        Update
                    </v-btn>
                    <v-btn
                        color="secondary"
                        v-show="activeTimeTracker != ''"
                        @click="setStopTime"
                    >
                        Stop
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<style scoped>
.place-top-right {
    position: absolute;
    top: 0;
    right: 0;
}
</style>

<script>
import * as data from '@/data/index';
import { mapState } from 'vuex';

export default {
    props: {
        value: Boolean
    },
    data() {
        return {
            selectedOperation: {},
            operations: [],
            timer: ''
        };
    },
    computed: {
        ...mapState(['activeTimeTracker', 'activeTimeTrackingOperation']),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        activeTimeTrackingOperation(newValue, oldValue) {
            this.selectedOperation = newValue;
        }
    },
    mounted() {
        this.loadOperations();
        this.selectedOperation = this.activeTimeTrackingOperation;
    },
    methods: {
        async loadOperations() {
            const operations = await data.config.getTimeTrackingOperations();
            if (!Object.keys(operations).length) {
                this.disableTimeTracking();
            }
            for (var key in operations) {
                if (Object.prototype.hasOwnProperty.call(operations, key)) {
                    this.operations.push({ id: key, name: operations[key] });
                }
            }
        },

        disableTimeTracking() {
            this.$emit('disableTimeTracking', true);
        },

        setStartTime() {
            let self = this;
            self.$root.$dialogLoader.show('Please wait…', {});
            data.timeTracker
                .startTimer(this.selectedOperation.id)
                .then(docRef => {
                    self.$store.dispatch('setActiveTimeTracker', docRef.id);
                    self.$store.dispatch(
                        'setActiveTimeTrackingOperation',
                        this.selectedOperation
                    );
                    self.$root.$dialogLoader.hide();
                    self.show = false;
                })
                .catch(error => {
                    console.error('Error adding document: ', error);
                    self.$root.$dialogLoader.hide();
                    self.show = false;
                });
        },

        setStopTime() {
            let self = this;
            self.$root.$dialogLoader.show('Please wait…', {});
            data.timeTracker
                .stopTimer()
                .then(() => {
                    self.$store.dispatch('setActiveTimeTracker', '');
                    self.$store.dispatch('setActiveTimeTrackingOperation', {});
                    this.selectedOperation = {};
                    self.$root.$dialogLoader.hide();
                    self.show = false;
                })
                .catch(error => {
                    console.error('Error updating document: ', error);
                    self.$root.$dialogLoader.hide();
                    self.show = false;
                });
        },

        updateOperation() {
            let self = this;
            self.$root.$dialogLoader.show('Please wait…', {});
            data.timeTracker
                .updateOperation(this.selectedOperation.id)
                .then(() => {
                    self.$store.dispatch(
                        'setActiveTimeTrackingOperation',
                        this.selectedOperation
                    );
                    self.$root.$dialogLoader.hide();
                    self.show = false;
                })
                .catch(error => {
                    console.error('Error upating opration: ', error);
                    self.$root.$dialogLoader.hide();
                    self.show = false;
                });
        }
    }
};
</script>
