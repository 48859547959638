<template>
    <v-row no-gutters align="center" justify="center">
        <v-col>
            <v-alert
                v-model="show_alert"
                color="secondary"
                border="left"
                colored-border
                type="warning"
                elevation="2"
                dismissible
            >
                {{ alert_message }}
            </v-alert>
            <div style="text-align:right; margin-bottom: 16px">
                <span
                    style="font-style:italic"
                    v-if="
                        activeTimeTrackingOperation &&
                            activeTimeTrackingOperation.name
                    "
                >
                    ({{ activeTimeTrackingOperation.name }})
                </span>
                <v-btn
                    color="secondary"
                    @click="showTimeTracker = true"
                    v-show="!disableTimeTracker"
                    >Time Tracker</v-btn
                >
            </div>

            <v-container class="grey lighten-5">
                <v-row class="dashboard-grid">
                    <v-col class="dash-card" cols="12" sm="4" md="4">
                        <v-btn
                            :disabled="getUnAssignedOrdersCount <= 0"
                            @click="showNextAvailableOrder"
                            elevation="2"
                            fab
                            ><v-icon color="secondary lighten-1"
                                >fa-rocket</v-icon
                            ></v-btn
                        >
                        <h3>Ready to Ship</h3>

                        <v-btn
                            small
                            color="secondary"
                            :dark="getUnAssignedOrdersCount > 0"
                            :disabled="getUnAssignedOrdersCount <= 0"
                            @click="showNextAvailableOrder"
                        >
                            Available Orders
                        </v-btn>
                    </v-col>
                    <v-col class="dash-card" cols="12" sm="4" md="4">
                        <v-btn
                            :disabled="getAssignedOrderCount <= 0"
                            @click="showNextAssignedOrder"
                            elevation="2"
                            fab
                            ><v-icon color="secondary lighten-1"
                                >fa-user-cog</v-icon
                            ></v-btn
                        >
                        <h3>Assigned to Me</h3>
                        <h4>
                            {{
                                getAssignedOrderCount == 50
                                    ? 'At least 50'
                                    : getAssignedOrderCount
                            }}
                        </h4>
                        <v-btn
                            small
                            color="secondary"
                            :dark="getAssignedOrderCount > 0"
                            :disabled="getAssignedOrderCount <= 0"
                            @click="showNextAssignedOrder"
                        >
                            Assigned Orders
                        </v-btn>
                    </v-col>
                    <v-col class="dash-card" cols="12" sm="4" md="4">
                        <v-btn router to="/search" elevation="2" fab>
                            <v-icon color="secondary lighten-1"
                                >fa-search</v-icon
                            >
                        </v-btn>
                        <h3>Search for Order</h3>
                        <v-btn small color="secondary" dark router to="/search">
                            Search for Order
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="dashboard-grid">
                    <v-col class="dash-card" cols="12" sm="4" md="4">
                        <v-btn router to="/cart" elevation="2" fab>
                            <v-icon color="secondary lighten-1"
                                >fa-barcode</v-icon
                            >
                        </v-btn>
                        <h3>Scan Cart</h3>
                        <v-btn small color="secondary" dark router to="/cart">
                            Scan Cart
                        </v-btn>
                    </v-col>
                    <v-col class="dash-card" cols="12" sm="4" md="4">
                        <v-btn router to="/product" elevation="2" fab>
                            <v-icon color="secondary lighten-1"
                                >fa-gifts</v-icon
                            >
                        </v-btn>
                        <h3>Scan Product</h3>
                        <v-btn
                            small
                            color="secondary"
                            dark
                            router
                            to="/product"
                        >
                            Scan Product
                        </v-btn>
                    </v-col>
                    <v-col class="dash-card" cols="12" sm="4" md="4">
                        <v-btn router to="/inventory-report" elevation="2" fab>
                            <v-icon color="secondary lighten-1"
                                >fa-list-alt</v-icon
                            >
                        </v-btn>
                        <h3>Inventory Report</h3>
                        <v-btn
                            small
                            color="secondary"
                            dark
                            router
                            to="/inventory-report"
                        >
                            Inventory Report
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="dashboard-grid">
                    <v-col class="dash-card" cols="12" sm="4" md="4">
                        <v-btn
                            router
                            to="/multi-order-picking-selection"
                            elevation="2"
                            fab
                        >
                            <v-icon color="secondary lighten-1">fa-bars</v-icon>
                        </v-btn>
                        <h3>Multi Order Pickup</h3>
                        <v-btn
                            small
                            color="secondary"
                            dark
                            router
                            to="/multi-order-picking-selection"
                        >
                            Start
                        </v-btn>
                    </v-col>
                </v-row>
                <TimeTracker
                    v-model="showTimeTracker"
                    @disableTimeTracking="disableTimeTracking"
                ></TimeTracker>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TimeTracker from '@/components/LogTimeTracker';

export default {
    name: 'Home',
    props: {
        show_alert: {
            default: false
        },
        alert_message: {
            default: ''
        }
    },
    data: () => {
        return {
            showTimeTracker: false,
            disableTimeTracker: false
        };
    },
    components: {
        TimeTracker
    },
    computed: {
        ...mapState(['currentUser', 'activeTimeTrackingOperation']),
        ...mapGetters(['getUnAssignedOrdersCount', 'getAssignedOrderCount'])
    },
    mounted() {},
    methods: {
        showNextAssignedOrder() {
            this.$store.dispatch('showNextAssignedOrder', 0);
            this.$router.push({
                name: 'Order',
                params: {
                    order_type: 'assigned',
                    show_next_order_button: true
                }
            });
        },
        showNextAvailableOrder() {
            this.$store.dispatch('showNextUnassignedOrder', 0);
            this.$router.push({
                name: 'Order',
                params: {
                    order_type: 'unassigned',
                    show_next_order_button: true
                }
            });
        },
        disableTimeTracking() {
            this.disableTimeTracker = true;
        }
    }
};
</script>
