<template>
    <v-card elevation="0" class="mx-auto text-center" width="100%">
        <v-alert
            v-show="error != ''"
            color="secondary"
            border="left"
            colored-border
            type="error"
            elevation="2"
            class="mt-6"
        >
            {{ error }}
        </v-alert>
        <v-card elevation="0" class="mt-12">
            <h1 class="justify-center">Time Tracker Reports</h1>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-select
                            v-model="selectedUser"
                            :items="users"
                            label="Please select a user"
                            item-value="id"
                            item-text="email"
                            outlined
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-menu
                            v-model="showDateStartFilter"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFilterStart"
                                    label="Start From"
                                    prepend-icon="fa-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                color="secondary"
                                v-model="dateFilterStart"
                                @input="showDateStartFilter = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-menu
                            v-model="showDateEndFilter"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFilterEnd"
                                    label="Start To"
                                    prepend-icon="fa-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                color="secondary"
                                v-model="dateFilterEnd"
                                @input="showDateEndFilter = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="secondary" @click="loadReport">Show Report</v-btn>
                <v-btn color="secondary" @click="resetFilters"
                    >Clear Filters</v-btn
                >
            </v-card-actions>
        </v-card>

        <v-card width="100%" flat class="mx-auto" v-if="showReport">
            <v-card-title>
                <span>
                    {{
                        selectedUser == ''
                            ? 'Time Tracking Report'
                            : selectedUser in usersEmailById
                            ? `Time Tracked by ${usersEmailById[selectedUser]}`
                            : 'Time Tracking Report'
                    }}</span
                >
            </v-card-title>
            <v-data-table
                v-if="!loadingData"
                :headers="headers"
                disable-sort
                hide-default-header
                :items="report"
                :items-per-page="10"
                disable-pagination
                hide-default-footer
                show-select
                class="elevation-2 mx-auto complete-table"
            >
                <template #body="{ items }">
                    <thead>
                        <tr>
                            <th>Operation</th>
                            <th>Start At</th>
                            <th>Stop At</th>
                            <th>User</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, i) in items"
                            :key="i"
                            :class="{ invalid: !item.stop }"
                        >
                            <td>
                                {{
                                    item.operation in operations
                                        ? operations[item.operation]
                                        : item.operation
                                }}
                            </td>
                            <td>
                                {{ convertUnixTimestampToDateTime(item.start) }}
                            </td>
                            <td>
                                {{ convertUnixTimestampToDateTime(item.stop) }}
                            </td>
                            <td>
                                {{
                                    item.user in usersEmailById
                                        ? usersEmailById[item.user]
                                        : item.user
                                }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card>
    </v-card>
</template>

<style scoped>
.invalid {
    background: #f5a3a3 !important;
}
</style>

<script>
import * as data from '@/data/index';
import { mapState } from 'vuex';
import { timeTracker } from '@/data/index';

export default {
    data() {
        return {
            error: '',
            selectedUser: '',
            showDateStartFilter: false,
            showDateEndFilter: false,
            dateFilterStart: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            dateFilterEnd: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            report: [],
            headers: [],
            operations: {},
            usersEmailById: {},
            loadingData: true,
            showReport: false
        };
    },
    computed: {
        ...mapState(['currentUser', 'users'])
    },
    mounted() {
        if (!this.currentUser.role.includes('ADMIN')) {
            this.$router.push({
                name: 'Home'
            });
        }
        this.loadUsers();
        this.loadOperations().then(() => {
            this.loadingData = false;
        });
    },
    methods: {
        loadReport() {
            let self = this;
            self.error = '';
            self.report = [];
            self.$root.$dialogLoader.show('Fetching data…', {});
            const start = Date.parse(this.dateFilterStart);
            const end = Date.parse(`${this.dateFilterEnd} 23:59:59`);
            timeTracker
                .getReport(start, end, this.selectedUser)
                .then(querySnapshot => {
                    querySnapshot.docs.every(snap => {
                        let data = snap.data();
                        if (data && data.operation) {
                            self.report.push(data);
                        }
                        return true;
                    });
                    if (self.report.length == 0) {
                        self.error = 'No record found!';
                        self.$root.$dialogLoader.hide();
                        return;
                    }
                    self.showReport = true;
                    self.$root.$dialogLoader.hide();
                })
                .catch(err => {
                    console.error(err);
                    self.$root.$dialogLoader.hide();
                });
        },

        async loadOperations() {
            const operations = await data.config.getTimeTrackingOperations();
            if (!Object.keys(operations).length) {
                this.$router.push({ name: 'Home' });
            }
            for (var key in operations) {
                if (Object.prototype.hasOwnProperty.call(operations, key)) {
                    this.operations[key] = operations[key];
                }
            }
        },

        loadUsers() {
            if (this.users && Object.keys(this.users).length) {
                this.users.every(user => {
                    this.usersEmailById[user.id] = user.email;
                    return true;
                });
            }
        },

        convertUnixTimestampToDateTime(timestamp) {
            if (!timestamp) {
                return timestamp;
            }
            let date = new Date(timestamp);
            return date.toLocaleString();
        },

        resetFilters() {
            this.$root.$dialogLoader.show('Please wait…', {});
            this.selectedUser = '';
            this.dateFilterStart = new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10);
            this.dateFilterEnd = new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10);
            this.report = [];
            this.showReport = false;
            this.$root.$dialogLoader.hide();
        }
    }
};
</script>
