<template>
    <v-card>
        <v-alert
            v-show="error != ''"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            class="mb-0"
        >
            {{ error }}
        </v-alert>

        <v-card-text class="grey lighten-5">
            <v-row>
                <v-col
                    v-for="item in items"
                    :key="item.cartId"
                    cols="12"
                    sm="6"
                    md="6"
                >
                    <v-card>
                        <v-card-title style="justify-content: center;"
                            ><barcode
                                v-bind:value="item.barcode"
                                format="CODE128"
                                display-value="false"
                                height="50"
                            >
                            </barcode
                        ></v-card-title>
                        <v-card-text>
                            <p>Order {{ item.cartId }}</p>
                            <p>Total Units {{ item.units }}</p>
                            <p>
                                Gift message -
                                {{ item.giftMessage !== '' ? 'Yes' : 'No' }}
                            </p>
                            <p v-show="item.shipLaterDate != ''">
                                Ship Later Date - {{ item.shipLaterDate }}
                            </p>
                        </v-card-text>
                    </v-card></v-col
                ></v-row
            >
        </v-card-text>
        <v-card-actions class="d-print-none">
            <v-btn color="primary" type="button" @click.prevent="printCartIds"
                >Print Cart ID's</v-btn
            >
            <v-btn
                color="primary"
                type="button"
                @click.prevent="printGiftMessages"
                >Print Gift Messages</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<script>
import VueBarcode from 'vue-barcode';
import * as data from '@/data/index';
import { jsPDF } from 'jspdf';

export default {
    props: {
        orders: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            error: '',
            items: [],
            shipStationPrefix: '',
            batchOrderGiftMessages: [],
            page: {
                size: {
                    width: 11,
                    height: 8.5
                },
                margins: {
                    top: 2.16,
                    right: 2.75,
                    bottom: 1,
                    left: 0.81
                }
            }
        };
    },
    components: { barcode: VueBarcode },
    mounted() {
        const self = this;
        if (self.orders.length === 0) {
            this.$router.push({
                name: 'Home',
                params: {
                    alert_message: 'No orders found in the batch!',
                    show_alert: true
                }
            });
        } else {
            this.items = this.orders.map(order => {
                return {
                    barcode: order.order.id,
                    cartId: order.order.id,
                    units: order.itemCount,
                    giftMessage: self.getOrderGiftMessages(order),
                    shipLaterDate: self.getShipLaterDate(order)
                };
            });
        }
    },
    methods: {
        getShipLaterDate(order) {
            return data.orders.getOrderShipLaterDate(order);
        },

        printCartIds() {
            window.print();
        },

        getOrderGiftMessages(order) {
            const giftMessage = data.orderGiftMessage.checkAndGetGiftMessage(
                order
            );
            if (giftMessage !== '') {
                this.batchOrderGiftMessages.push({
                    orderId: order.order.id,
                    giftMessage: giftMessage
                });
            }
            return giftMessage;
        },

        printGiftMessages() {
            const self = this;
            const doc = new jsPDF({
                orientation: 'landscape',
                unit: 'in',
                format: [self.page.size.width, self.page.size.height]
            });
            doc.setFont('Times', 'bolditalic');
            doc.setFontSize(16);
            let i = 1;

            self.batchOrderGiftMessages.map(item => {
                let orderId = item.orderId;
                let giftMessage = item.giftMessage;
                if (giftMessage !== '') {
                    if (i > 1) {
                        // Each gift message will be printed on a new page.
                        doc.addPage({
                            orientation: 'landscape',
                            unit: 'in',
                            format: [
                                self.page.size.width,
                                self.page.size.height
                            ]
                        });
                    }

                    doc.text(
                        orderId.toString(),
                        doc.internal.pageSize.getWidth() -
                            this.page.margins.right,
                        this.page.margins.top + 0.55,
                        { align: 'right' }
                    );

                    doc.text(
                        giftMessage,
                        (doc.internal.pageSize.getWidth() -
                            this.page.margins.right) /
                            2,
                        this.page.margins.top + 1.38,
                        { align: 'center' }
                    );
                    i++;
                }
            });
            doc.save('batch_orders_gift_message.pdf');
        }
    }
};
</script>
