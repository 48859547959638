<template>
    <v-card width="100%" class="mx-auto">
        <v-card-title class="">Inventory Report</v-card-title>
        <v-alert
            v-show="error != ''"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
        >
            {{ error }}
        </v-alert>

        <ScannableInput
            :active="isScanningActive"
            :debug="false"
            @scan="scan"
        ></ScannableInput>

        <v-card-text>
            <p v-show="isScanningActive == false">
                Please select an option before scanning for UPC.
            </p>
            <v-container class="px-0" fluid>
                <div class="row py-6">
                  <v-card-actions class="fullwidth text-center d-flex">
                    <v-btn color="primary"
                           @click.prevent="submitReport"
                    >Submit Report</v-btn
                    >
                    <v-btn
                      class="ml-auto"
                      color="success"
                      @click.prevent="downloadReport"
                      v-show="isReportReady"
                    >
                      <span class="d-none d-sm-inline">Download</span>
                      <v-icon class="d-sm-none" x-small>
                        fa-download
                      </v-icon></v-btn
                    >
                    <v-btn
                      color="error"
                      @click.prevent="deleteReport"
                      v-show="isReportReady"
                    >
                      <span class="d-none d-sm-inline">Delete</span>
                      <v-icon class="d-sm-none" x-small>
                        fa-trash
                      </v-icon></v-btn
                    >
                  </v-card-actions>
                </div>
                <div class="options row">
                    <label class="option col-md-3 col-6" for="send-product-to-store">
                        <input
                            type="radio"
                            name="option"
                            id="send-product-to-store"
                            value="Send Product to Store"
                            v-model="option"
                        />
                        <div class="option-content py-4">
                          <v-icon color="secondary lighten-1" class="py-2">fa-paper-plane</v-icon>
                          <h3 class="pt-4">Send Product to Store</h3>
                        </div>
                    </label>
                    <label class="option col-md-3 col-6" for="trash-product">
                        <input
                            type="radio"
                            name="option"
                            id="trash-product"
                            value="Trash Product"
                            v-model="option"
                        />
                        <div class="option-content py-4">
                          <v-icon color="secondary lighten-1" class="py-2">fa-trash</v-icon>
                          <h3 class="pt-4">Trash Product</h3>
                        </div>
                    </label>
                    <label class="option col-md-3 col-6" for="add-into-inventory">
                        <input
                            type="radio"
                            name="option"
                            id="add-into-inventory"
                            value="Add into Inventory"
                            v-model="option"
                        />
                        <div class="option-content py-4">
                          <v-icon color="secondary lighten-1" class="py-2">fa-plus</v-icon>
                          <h3 class="pt-4">Add into Inventory</h3>
                        </div>
                    </label>
                    <label class="option col-md-3 col-6" for="other">
                        <input
                            type="radio"
                            name="option"
                            id="other"
                            value="Other"
                            v-model="option"
                        />
                        <div class="option-content py-4">
                          <v-icon color="secondary lighten-1" class="py-2">fa-ellipsis-h</v-icon>
                          <h3 class="pt-4">Other</h3>
                        </div>
                    </label>
                    <v-textarea
                        auto-grow
                        outlined
                        rows="1"
                        row-height="15"
                        placeholder="Please type a reason"
                        v-show="option == 'Other'"
                        v-model="otherOption"
                    ></v-textarea>
                </div>
            </v-container>
        </v-card-text>
        <template>
            <div v-show="isScanningActive == true">
                <v-card-title>Scanned Items</v-card-title>
                <v-data-table :headers="reportHeaders" :items="reportItems">
                    <template v-slot:item.qty="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.qty"
                            @open="open"
                            @close="close"
                            @cancel="cancel"
                        >
                            {{ props.item.qty }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="props.item.qty"
                                    label="Edit"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                </v-data-table>
            </div>
        </template>
    </v-card>
</template>
<script>
import * as data from '@/data/index';
import ScannableInput from '@/components/ScannableInput';

export default {
    name: 'InventoryReport',
    data() {
        return {
            error: '',
            isScanningActive: false,
            option: '',
            otherOption: '',
            reportHeaders: [
                { text: 'Option', sortable: false, value: 'option' },
                { text: 'UPC scanned', sortable: false, value: 'upc' },
                { text: 'SKU', sortable: false, value: 'sku' },
                { text: 'QTY', sortable: false, value: 'qty' }
            ],
            reportItems: [],
            isReportReady: false,
            reportUid: ''
        };
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        option: function(newValue, oldValue) {
            if (newValue !== '') {
                this.isScanningActive = true;
            }
        }
    },
    components: {
        ScannableInput
    },
    methods: {
        scan(response) {
            let self = this;
            self.error = '';
            self.$root.$dialogLoader.show('Finding the product…', {});

            data.products.getProductByUpc(response.input).then(
                result => {
                    if ('data' in result && result.data.length > 0) {
                        let product = result.data[0];
                        let item = {
                            option:
                                self.option === 'Other'
                                    ? `Other (${self.otherOption})`
                                    : self.option,
                            upc: product.upc,
                            sku: product.sku,
                            qty: 1
                        };
                        self.reportItems.push(item);
                    } else {
                        self.error =
                            "We can't find a product matching the barcode.";
                    }
                    self.$root.$dialogLoader.hide();
                },
                error => {
                    self.error =
                        'There was an error in fetching product from BigCommerce.';
                    console.log(error);
                    self.$root.$dialogLoader.hide();
                }
            );
        },

        open() {
            this.isScanningActive = false;
        },

        close() {
            this.isScanningActive = true;
        },

        cancel() {
            this.isScanningActive = true;
        },

        saveQty(item) {
            item.qty = this.editedQty;
        },

        submitReport() {
            let self = this;
            self.$root.$dialogLoader.show('Saving report…', {});

            const chosenOption =
                self.option === 'Other'
                    ? `Other (${self.otherOption})`
                    : self.option;
            data.inventoryReport
                .saveReport(chosenOption, self.reportItems)
                .then(doc => {
                    this.reportUid = doc.id;
                    this.isReportReady = true;
                    self.$root.$dialogLoader.hide();
                })
                .catch(err => {
                    console.error(err);
                    self.error =
                        'There was a problem saving report to Firebase';
                    self.$root.$dialogLoader.hide();
                });
        },

        downloadReport() {
            let self = this;
            self.$root.$dialogLoader.show('Downloading report…', {});
            let csv = 'Option,UPC Scanned,SKU,QTY\n';
            self.reportItems.forEach(row => {
                csv += Object.values(row).join(',');
                csv += '\n';
            });

            const anchor = document.createElement('a');
            anchor.href =
                'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'inventory-report.csv';
            anchor.click();
            self.$root.$dialogLoader.hide();
        },

        deleteReport() {
            let self = this;
            data.inventoryReport
                .deleteReport(this.reportUid)
                .then(() => {
                    self.$router.push({
                        name: 'Home'
                    });
                })
                .catch(err => {
                    console.error(err);
                    self.error = 'There was a problem in deleting report.';
                });
        }
    }
};
</script>
<style lang="scss" scoped>
  .option {
      .option-content {

      }
      input[type="radio"] {
          position: fixed;
          left: -999999px;
          top: -999999px;
          &:checked {
              & + .option-content {
                  background: rgba(0, 0, 0, 0.1);
                  border-radius: 10px;
                  i {
                      &:before {
                          text-shadow: 3px 3px 5px rgba(255, 255, 255, 0.9);
                      }
                  }
              }
          }
          &#send-product-to-store:checked + .option-content i:before {
              color: darkgreen;
          }
          &#add-into-inventory:checked + .option-content i:before {
              color: darkblue;
          }
          &#trash-product:checked + .option-content i:before {
              color: darkred;
          }
      }
  }
</style>
