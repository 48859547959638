<template>
    <v-card width="100%" class="mx-auto">
        <v-card-title>Search Orders by Email</v-card-title>
        <v-alert
            v-show="error != ''"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
        >
            {{ error }}
        </v-alert>

        <v-form>
            <v-card-text>
                <v-text-field
                    label="Email"
                    v-model.trim="email"
                    :rules="[rules.required, rules.email]"
                />
            </v-card-text>
            <v-card-actions class="px-4 py-4">
                <v-btn
                    color="primary"
                    type="submit"
                    v-show="isSearchCompleted == false"
                    @click.prevent="searchForOrders"
                    >Search</v-btn
                >
            </v-card-actions>
            <v-card-subtitle v-show="getSearchedOrdersCount > 0"
                >A total of <b>{{ getSearchedOrdersCount }}</b> order(s) found
                matching the email
                <b
                    ><i>"{{ email }}"</i></b
                ></v-card-subtitle
            >
            <v-card-actions
                class="px-4 py-4"
                v-show="isSearchCompleted == true"
            >
                <v-btn
                    color="primary"
                    type="button"
                    @click.prevent="mergeOrders"
                    >Merge all orders</v-btn
                >
                <v-btn
                    color="primary"
                    type="button"
                    @click.prevent="resetSearch"
                    >Search again</v-btn
                >
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import * as data from '@/data/index';
import { mapState } from 'vuex';

export default {
    name: 'BatchOrderEmailSearch',
    data() {
        return {
            error: '',
            email: '',
            rules: {
                required: value => !!value || 'The field is required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Please enter a valid email.';
                }
            },
            isSearchCompleted: false,
            searchedOrders: []
        };
    },

    computed: {
        ...mapState(['currentUser']),
        getSearchedOrdersCount() {
            return this.searchedOrders.length;
        }
    },

    methods: {
        async searchForOrders() {
            const self = this;
            self.error = '';
            self.$root.$dialogLoader.show('Searching for orders...', {});
            data.orders
                .getOrdersByEmail(self.email)
                .then(querySnapshot => {
                    self.searchedOrders = querySnapshot.docs.map(doc =>
                        doc.data()
                    );
                    if (self.searchedOrders.length > 0) {
                        self.isSearchCompleted = true;
                    } else {
                        self.error = `There was no new order found matching matching the Email (${self.email})`;
                    }
                    self.$root.$dialogLoader.hide();
                })
                .catch(err => {
                    console.error(err);
                    self.$root.$dialogLoader.hide();
                });
        },

        async mergeOrders() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'Are you sure you want to merge all orders?',
                    {}
                )
            ) {
                const self = this;
                self.$root.$dialogLoader.show('Merging Orders..', {});

                let mergedOrders = self.searchedOrders;
                const mainOrder = mergedOrders.shift();
                const mainOrderId = mainOrder.order.id;
                const mergedOrderIds = mergedOrders.map(
                    order => order.order.id
                );

                data.orders
                    .markMergedOrderIds(mainOrderId, mergedOrderIds)
                    .then(mainOrder => {
                        return data.orders.mergeOrderItems(mainOrder.data());
                    })
                    .then(order => {
                        return self.$store.dispatch(
                            'assignCurrentOrder',
                            order.data().order.id
                        );
                    })
                    .then(() => {
                        return self.completeMergedOrderItemsManually(
                            mergedOrders
                        );
                    })
                    .then(() => {
                        return this.completeMergedOrdersManually(
                            mergedOrders,
                            mainOrderId
                        );
                    })
                    .then(() => {
                        self.$router.push({
                            name: 'Order',
                            params: {
                                order_type: '',
                                show_next_order_button: false,
                                show_alert: false,
                                alert_message: ''
                            }
                        });
                        self.$root.$dialogLoader.hide();
                    })
                    .catch(err => {
                        self.error = 'There was a problem in merging orders';
                        self.$root.$dialogLoader.hide();
                        console.error(err);
                    });
            }
        },

        completeMergedOrderItemsManually(orders) {
            const self = this;
            let promises = orders.map(order => {
                return self.completeOrderItemsManually(order);
            });
            return Promise.all(promises);
        },

        async completeOrderItemsManually(order) {
            const self = this;
            order.verification = await data.orders.getVerificationForOrder(
                order,
                this.currentUser
            );
            let promises = order.verification.items.map(item => {
                return self.$store.dispatch('updateVerificationItem', {
                    orderId: order.order.id,
                    itemId: item.orderItem.id,
                    data: {
                        status: 'complete',
                        confirm: {
                            method: 'merge'
                        }
                    }
                });
            });
            return Promise.all(promises);
        },

        completeMergedOrdersManually(orders, mainOrderId) {
            const self = this;
            let promises = orders.map(order => {
                return self.$store
                    .dispatch('updateOrder', {
                        orderId: order.order.id,
                        data: {
                            merged_into_order: mainOrderId,
                            internalStatus: 'merged',
                            assigned: null
                        }
                    })
                    .then(() => {
                        return data.orderHistory.save({
                            orderId: order.order.id,
                            action: 'completed',
                            description:
                                'Order is marking as completed since it has been merged into order ' +
                                mainOrderId
                        });
                    });
            });
            return Promise.all(promises);
        },

        resetSearch() {
            this.$router.go();
        }
    }
};
</script>
